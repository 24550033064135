import React from "react"

function PatternCTABoxLightLp() {
  return (
    <div className="pattern-cta-box">
      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/AAKGq9HROuzSwbDuSfj2"
        className="top-left position-absolute"
        alt="ellipsis"
      />
      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/3Gm5GRrFQFKj9uQbqUbM"
        className="top-right position-absolute"
        alt="ellipsis"
      />
      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/NDDLcbB4TjeqYlqU5jrD"
        className="bottom-left position-absolute"
        alt="ellipsis"
      />

      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/cpeQ1rRRQ9e498GGAcAj"
        className="bottom-right position-absolute"
        alt="ellipsis"
      />

      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/ZVdVqFSrRNqbi82BZ8Ex"
        className="mob-top-left position-absolute"
        alt="ellipsis"
      />
      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/Ew3F8gCBSP2icHSYNx3T"
        className="mob-top-right position-absolute pat-lp-left"
        alt="ellipsis"
      />
      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/R6TSxbTuS6Kn2EEf8wHR"
        className="mob-bottom-left position-absolute pat-lp-right"
        alt="ellipsis"
      />

      <img
        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/BBqFhRuhSHqXtjOvuGRC"
        className="mob-bottom-right position-absolute"
        alt="ellipsis"
      />
    </div>
  )
}

export default PatternCTABoxLightLp
