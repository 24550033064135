import React, { useState } from "react"
import Fade from "react-reveal/Fade"

import "../../styles/component/tabs.scss"

import Buttons from "../common/button"
import PulsatingRing from "../utility/pulsating_ring"

function InteractiveTabs(props) {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <>
      <div className="interactive position-relative">
        <img
          src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/4uhErLu7RcWo4gDkAKPa"
          className="layer"
          alt="tabs"
          height="100"
          width="100"
        />
        <div className="wrap position-absolute">
          {props.title.map((title, i) => {
            return (
              <div
                className={`item position-relative ${i === activeTab &&
                  "active"}`}
                onClick={() => setActiveTab(i)}
                onKeyDown={() => setActiveTab(i)}
                role="button"
                tabIndex={0}
              >
                {i === activeTab ? (
                  <div className="circle position-absolute" />
                ) : (
                  <PulsatingRing />
                )}
                <span className="p12">
                  <p>{title}</p>
                </span>
              </div>
            )
          })}

          <div
            className={`item position-relative`}
            onClick={props.togglePopup}
            onKeyDown={props.togglePopup}
            role="button"
            tabIndex={0}
          >
            <PulsatingRing />
            <span className="p12">
              <p>{props.ctaText}</p>
            </span>
          </div>
        </div>
        <div className="screens">
          {props.image.map((image, index) => {
            return (
              <Fade>
                <img
                  src={image.url}
                  alt="screen shots"
                  height="100"
                  width="100"
                  className="position-absolute"
                  style={{ display: index !== activeTab && "none" }}
                />
              </Fade>
            )
          })}
        </div>
      </div>

      <div className="static position-relative">
        <div className="screens">
          {props.image.map((image, index) => {
            return (
              <>
                <span className="p16">
                  <p> {props.title[index]} </p>
                </span>
                <img
                  src={image.url}
                  alt="screen shots"
                  height="100"
                  width="100"
                />
              </>
            )
          })}
        </div>
        <div className="mt-5">
          <Buttons
            theme="primary w-100"
            onClick={props.togglePopup}
            text={props.ctaText}
          />
        </div>
      </div>
    </>
  )
}

export default InteractiveTabs
